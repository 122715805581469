<template>
  <div :class="`mb-3 ${divClass}`">
    <s-label :label="label" :required="required" />
    <div class="input-group">
      <span class="input-group-text text-sm iconButton" v-if="addonIcon" @click="emitCopy">
        <i :class="addonIcon"></i>
      </span>
      <span class="input-group-text text-sm iconButton" v-if="videoIcon" @click="emitVideo">
        <i :class="videoIcon"></i>
      </span>
      <input
      :ref="ref"
      v-model="inputValue"
      :disabled="isDisabled"
      type="text"
      @input="updateValue"
      :placeholder="placeholder"
      :minlength="minlength"
      :maxlength="maxlength"
      class="form-control form-control-sm text-sm"
      :class="{ 'is-invalid': hasError }"
      @blur="updateValue"
      @keydown.enter.prevent="emitEnter"
      />
      <div class="invalid-feedback text-sm" v-if="hasError">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { validateRequired } from '@/rule/validate'

export default defineComponent({

  props: {
    ref: String,
    divClass: String,
    label: String,
    isDisabled: Boolean,
    placeholder: String,
    required: Boolean,
    minlength: String,
    maxlength: String,
    modelValue: [String, Number],
    addonIcon: {
      type: String,
      default: '',
    },
    videoIcon: {
      type: String,
      default: '',
    }
  },

  inheritAttrs: false,

  data: () => ({
    inputValue: '',
    error: null,
    hasError: false,
  }),

  methods: {
    update() { this.$emit('update:modelValue', this.inputValue) },

    updateValue() {
      validateRequired(
        this.inputValue,
        this.required,
        this,
        `${this.label} é obrigatório.`,
        this.minlength,
        `${this.label} deve ter no mínimo ${this.minlength} caracteres.`
      )

      this.update()
    },

    emitEnter() {
      this.$emit("enter")
    },

    emitCopy() {
      this.$emit("clickCopy", this.inputValue)
    },

    emitVideo() {
      this.$emit("clickVideo")
    }
  },

  emmits: [ "update:modelValue" ],

  created() { this.inputValue = this.modelValue },

  watch: {
    inputValue() { this.update() },

    modelValue() {
      if (this.modelValue != this.inputValue) {
        this.inputValue = this.modelValue
      }
    }
  },
})
</script>

<style>
.input-group-text {
  background-color: #F3EEFF;
  border: none;
}

.iconButton {
  cursor: pointer;
}

input[type=text]:focus {
  background-color: #F3EEFF;
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
}
</style>