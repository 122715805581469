<template>
  <div class="col-12">
    <span class="text-danger text-sm"><b>* </b>Campo obrigatório.</span>
  </div>
</template>

<script>
export default {
  name: "TheRequiredLabel"
}
</script>

<style></style>