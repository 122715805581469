<template>
  <div class="row vh-100 vw-100 div-main" v-if="!logged">
    <s-login-form />
    <s-login-content class="login-content" />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["logged"]),
  },
};
</script>

<style>
.div-main {
  margin: 0;
  padding: 0;
}


@media (max-width: 767px) {
  .login-content {
    display: none;
  }
}
</style>