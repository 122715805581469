<template>
  <div class="invalid-feedback text-sm">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TheInvalidFeedback'
}
</script>

<style></style>