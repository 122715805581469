import { nextTick } from 'vue'
import store from '@/store/index'
import { setHeaders } from '@/crud.js'

export async function validateForm(ref) {
  if (!ref) {
    return false
  }

  const elementInput = ref.getElementsByTagName('input')
  const elementSelect = ref.getElementsByTagName('select')
  let valid = true
  let countInvalid = 0

  for (let i = 0; i < elementInput.length; i++) {
    const element = elementInput[i]
    element.focus()
    element.blur()
    await nextTick()

    if (element.className.includes('is-invalid') || element.className.includes('dp-custom-input')) {
      countInvalid++
    }
  }

  for (let i = 0; i < elementSelect.length; i++) {
    const element = elementSelect[i]
    element.focus()
    element.blur()
    await nextTick()

    if (element.className.includes('is-invalid')) {
      countInvalid++
    }
  }

  if (countInvalid > 0) {
    valid = false
  }
  return valid
}

export function getRouteName(route) {
  let theRoute = {
    user: {
      parent: 'Administração',
      name: 'Usuários',
      path: 'user',
      childrenName: 'Cadastro de Usuário',
      childrenPath: 'userNew',
      UpdateName: 'Edição de Usuário',
      UpdatePath: 'userUpdate',
    },
    userGroup: {
      parent: 'Usuários',
      name: 'Grupo de Usuários',
      path: 'userGroup',
      childrenName: 'Cadastro de Grupo',
      childrenPath: 'userGroupNew',
      UpdateName: 'Edição de Grupo',
      UpdatePath: 'userGroupUpdate',
    },
    userGroupChildren: {
      parent: 'Usuários',
      name: 'Grupo de Usuários',
      path: 'userGroupChildren',
      childrenName: '',
      childrenPath: '',
    },
    screen: {
      parent: 'Administração',
      name: 'Programas',
      path: 'screen',
      childrenName: 'Cadastro de Programas',
      childrenPath: 'screenNew',
      UpdateName: 'Edição de Programas',
      UpdatePath: 'screenUpdate',
    },
    acl: {
      parent: 'Administração',
      name: 'Acl',
      path: 'acl',
      childrenName: 'Cadastro de Acl',
      childrenPath: 'aclNew',
      UpdateName: 'Edição de Acl',
      UpdatePath: 'aclUpdate',
    },
    aclChildren: {
      parent: 'Grupos',
      name: 'Acl',
      path: 'aclChildren',
      childrenName: '',
      childrenPath: '',
    },
    group: {
      parent: 'Administração',
      name: 'Grupos',
      path: 'group',
      childrenName: 'Cadastro de Grupo',
      childrenPath: 'groupNew',
      UpdateName: 'Edição de Grupo',
      UpdatePath: 'groupUpdate',
    },
    record: {
      parent: 'Registros',
      name: 'Vagas',
      path: 'record',
      // childrenName: 'Cadastro de Grupo',
      // childrenPath: 'groupNew',
      // UpdateName: 'Edição de Grupo',
      // UpdatePath: 'groupUpdate',
    },
    question: {
      parent: 'Registros',
      name: 'Perguntas',
      path: 'question',
      // childrenName: 'Cadastro de Grupo',
      // childrenPath: 'groupNew',
      // UpdateName: 'Edição de Grupo',
      // UpdatePath: 'groupUpdate',
    },
    candidate: {
      parent: 'Vagas',
      name: 'Candidatos',
      path: 'candidate',
      // childrenName: 'Cadastro de Grupo',
      // childrenPath: 'groupNew',
      // UpdateName: 'Edição de Grupo',
      // UpdatePath: 'groupUpdate',
    },
    jobQuestions: {
      parent: 'Vagas',
      name: 'Perguntas as Vagas',
      path: 'jobQuestions',
      childrenName: 'Cadastro de Perguntas as Vagas',
      childrenPath: 'jobQuestionsNew',
      UpdateName: 'Edição de Perguntas as Vagas',
      UpdatePath: 'jobQuestionsUpdate',
    },
    curriculum: {
      parent: 'Candidatos',
      name: 'Curriculo',
      path: 'curriculum',
    },
    // childrenName: 'Cadastro de Grupo',
    // childrenPath: 'groupNew',
    // UpdateName: 'Edição de Grupo',
    // UpdatePath: 'groupUpdate',
    processor: {
      parent: 'Administração',
      name: 'Monitor de Processos',
      path: 'processor',
      childrenName: '',
      childrenPath: '',
      UpdateName: '',
      UpdatePath: '',
    },
    myProfile: {
      parent: '',
      name: '',
      path: '',
      childrenName: '',
      childrenPath: '',
      UpdateName: '',
      UpdatePath: '',
    },
  }

  if (route in theRoute) {
    return theRoute[route]
  } else if (route.split('New')[0] in theRoute) {
    return theRoute[route.split('New')[0]]
  } else {
    return theRoute[route.split('Update')[0]]
  }
}

export function logout(vueInstance) {
  vueInstance.$store.dispatch('setUser', false)
  vueInstance.$store.dispatch('setLogged', false)
  vueInstance.$router.push({ name: 'login' })
  localStorage.removeItem('user')
}

export function cleanObject(object) {
  if (object.status && !object.password) {
    delete object.id
    delete object.createdAt
    delete object.deletedAt
    delete object.updatedAt
  } else {
    delete object.id
    delete object.status
    delete object.createdAt
    delete object.deletedAt
    delete object.updatedAt
  }
}

export async function checkSession() {
  const rawUser = localStorage.getItem('user')

  if (!rawUser) {
    return false
  }

  const user = JSON.parse(rawUser)

  if (!user.token) {
    return false
  }

  setHeaders(user.token)
  store.dispatch('setLogged', true)
  store.dispatch('setUser', user)
  return true
}
