<template>
  <section class="mx-2">
    <span class="text-lg">
      <b>{{ title }}</b>
    </span>
    <s-breadcumb v-if="breadcrumb" />
  </section>
</template>

<script>
export default {
  props: {
    title: String,
    breadcrumb: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style></style>
