<template>
  <div class="wrapper vh-100">
    <nav ref="sidebar" class="sidebar s-bg-dark" v-if="logged">
      <div class="text-center my-5">
        <img src="@/assets/img/logowhite-dashboard.png" />
      </div>
      <s-sidemenu :menuList="menuList" class="px-3" />
    </nav>
    <div id="content" class="content text-bg-light p-0" style="overflow-y: auto; max-height: calc(150vh - 60px)">
      <router-view />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { baseApiUrl } from '@/global'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  setup() {
    const sidebar = ref(null)

    const toggleSidebar = () => {
      sidebar.value.classList.toggle('active')
    }

    return { sidebar, toggleSidebar }
  },

  computed: {
    ...mapState(['logged', 'user']),
  },

  data: () => ({
    menuList: [
      {
        code: 'dashboard',
        title: 'Dashboard',
        status: false,
        icon: 'bi bi-house-fill',
        children: [],
      },
      {
        code: 'record',
        title: 'Vagas',
        status: false,
        icon: 'bi bi-card-list',
        children: [],
      },
      {
        code: 'question',
        title: 'Perguntas',
        status: false,
        icon: 'bi bi-card-list',
        children: [],
      },
      {
        code: null,
        title: 'Administração',
        status: false,
        icon: 'bi bi-gear-fill',
        children: [
          {
            code: 'group',
            title: 'Grupos',
            status: false,
            icon: 'bi bi-circle',
            child: 'acl',
            children: [],
          },
          {
            code: 'screen',
            title: 'Programas',
            status: false,
            icon: 'bi bi-circle',
            children: [],
          },
          {
            code: 'user',
            title: 'Usuários',
            icon: 'bi bi-circle',
            child: 'userGroup',
            status: false,
          },
        ],
      },
      {
        code: 'separator',
        isSeparator: true,
        children: [],
      },
      {
        code: 'linkButton',
        title: 'Niil Assessement',
        status: false,
        icon: 'bi bi-link-45deg',
        url: 'https://www.niilassessment.com.br/',
        children: [],
      },
      {
        code: 'linkButton',
        title: 'Niil IA',
        status: false,
        icon: 'bi bi-link-45deg',
        url: 'https://www.niilia.com.br/',
        children: [],
      },
      {
        code: 'linkButton',
        title: 'Niil Recruiter',
        status: false,
        icon: 'bi bi-link-45deg',
        url: 'https://www.niilrecruiter.com.br/',
        children: [],
      },
    ],
  }),

  methods: {
    logout() {
      localStorage.removeItem('user')
      this.$store.dispatch('setLogged', false)
      this.$store.dispatch('setUser', null)
      this.$router.push({ name: 'login' })
    },
  },
}
</script>

<style>
.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
}

.sidebar {
  min-width: 250px;
  max-width: 250px;
  transition: all 0.3s;
  position: relative;
}

.sidebar .sidebarBottom {
  position: fixed;
  bottom: 0;
  left: 0;
  max-height: 10%;
  width: 250px;
  padding: 10px;
}

.sidebar.active {
  margin-left: -250px;
}

.content {
  width: 100%;
  height: 100%;
  min-height: 95vh;
  transition: all 0.3s;
}

.navbar {
  height: 5vh;
}

/* .item-firstLevel:hover {
  color: #000;
  background-color: #8dc4f1;
} */

.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: #acacac;
}

/* .btn-toggle::after {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform 0.35s ease;
  transform-origin: 0.5em 50%;
} */

.btn-toggle[aria-expanded='true']::after {
  transform: rotate(450deg);
}

@media (max-width: 768px) {
  .sidebar {
    margin-left: -250px;
  }

  .sidebar.active {
    margin-left: 0;
  }

  .sidebarCollapse span {
    display: none;
  }
}

@media screen and (width < 1550px) {
  .content {
    min-height: 90vh;
  }

  .navbar {
    height: 10vh;
  }

  .icon {
    font-size: 18px;
    cursor: pointer;
  }
}
</style>
