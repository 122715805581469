<template>
  <div
    class="modal modal-xl fade"
    ref="modalPattern"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header s-bg-primary s-text-light">
          <span class="fs-5 text-white">Selecionar Registros</span>
          <button
            type="button"
            class="btn-close btn-close-white"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body text-dark">
          <slot></slot>
        </div>
        <div class="modal-footer">

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "modalPattern",
}
</script>

<style></style>