<template>
  <div :class="`mb-3 ${divClass}`">
    <s-label :label="label" :required="required" />
    <div class="input-group">
      <span class="input-group-text text-sm iconButton" v-if="addonIcon" @click="emitCopy">
        <i :class="addonIcon"></i>
      </span>
      <span class="input-group-text text-sm iconButton" v-if="videoIcon" @click="emitVideo">
        <i :class="videoIcon"></i>
      </span>
      <input
        :ref="ref"
        v-model="inputValue"
        :disabled="isDisabled"
        @input="updateValue"
        :placeholder="placeholder"
        type="range"
        min="1"
        max="100"
        class="slider form-control form-control-sm text-sm"
        :class="{ 'is-invalid': hasError }"
        @blur="updateValue"
        @keydown.enter.prevent="emitEnter" />
      <div class="invalid-feedback text-sm" v-if="hasError">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { validateRequired } from '@/rule/validate'

export default defineComponent({
  props: {
    ref: String,
    divClass: String,
    label: String,
    isDisabled: Boolean,
    placeholder: String,
    required: Boolean,
    minlength: String,
    maxlength: String,
    modelValue: [String, Number],
    addonIcon: {
      type: String,
      default: '',
    },
    videoIcon: {
      type: String,
      default: '',
    },
  },

  inheritAttrs: false,

  data: () => ({
    inputValue: '',
    error: null,
    hasError: false,
  }),

  methods: {
    update() {
      this.$emit('update:modelValue', this.inputValue)
    },

    updateValue() {
      validateRequired(
        this.inputValue,
        this.required,
        this,
        `${this.label} é obrigatório.`,
        this.minlength,
        `${this.label} deve ter no mínimo ${this.minlength} caracteres.`
      )

      this.update()
    },

    emitEnter() {
      this.$emit('enter')
    },

    emitCopy() {
      this.$emit('clickCopy', this.inputValue)
    },

    emitVideo() {
      this.$emit('clickVideo')
    },
  },

  emmits: ['update:modelValue'],

  created() {
    this.inputValue = this.modelValue
  },

  watch: {
    inputValue() {
      this.update()
    },

    modelValue() {
      if (this.modelValue != this.inputValue) {
        this.inputValue = this.modelValue
      }
    },
  },
})
</script>

<style>
.input-group-text {
  background-color: #f3eeff;
  border: none;
}

.iconButton {
  cursor: pointer;
}

input[type='text']:focus {
  background-color: #f3eeff;
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
}

.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: rgb(96, 42, 132); /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: rgb(96, 42, 132); /* Green background */
  cursor: pointer; /* Cursor on hover */
}
</style>
