<template>
  <div class="text-center text-secondary loader-overlay">
    <div class="spinner-border text-dark" style="width: 4rem; height: 4rem;" role="status"></div>
    <h6 class="text-dark my-4">{{ text }}</h6>
  </div>
</template>

<script>
  export default ({
    name: 'TheLoader',
    props: {
      text: {
        type: String,
        default: 'Carregando dados, aguarde...'
      },
    }
  })
</script>

<style>
  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
</style>