<template>
  <div
    class="s-tooltip"
    @mouseover="open = true"
    @mouseout="open = false"
    :style="`--main-bg-color: var(--${color});`"
  >
    <slot></slot>
    <transition name="fade">
      <div class="tooltip-label" v-if="(open || active) && label">
        {{ label }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    color: {
      type: String,
      default: "primary",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    open: false,
  }),
};
</script>

<style>
.s-tooltip {
  display: inline-block;
  position: relative;
}

.tooltip-label {
  display: block;
  position: absolute;
  font-size: 12px;
  text-align: center;
  min-height: 22px;
  min-width: 100px;
  max-width: 220px;
  bottom: calc(100% + 15px);
  left: -10px;
  border-radius: 5px;
  padding: 5px 8px;
  z-index: 10;
  color: white;
  background: var(--main-bg-color);
  overflow-wrap: break-word;
}

.tooltip-label:before {
  content: "";
  position: absolute;
  top: calc(100%);
  left: 10px;
  border-style: solid;
  border-width: 10px;
  border-color: var(--main-bg-color) transparent transparent transparent;
}

.fade-enter-active {
  transition: all 0.2s ease-in;
}

.fade-leave-active {
  transition: all 0.2s ease-out;
}

.fade-enter-from {
  opacity: 0;
}

.fade-leave-to {
  opacity: 0;
}
</style>
