<template>
  <div :class="`mb-3 ${divClass}`">
    <s-label :label="divLabel" :required="required" />
    <div>
      <div class="form-check form-check-inline align-middle">
        <input
          :ref="ref"
          v-model="inputValue"
          class="form-check-input"
          type="checkbox"
          :disabled="isDisabled"
          @change="update"
          @click="emitClick"
        />
        <label :class="`form-check-label text-sm ${labelClass}`"> {{ label }} </label>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheCheck',
  inheritAttrs: false,

  props: {
    ref: String,
    divClass: String,
    labelClass: String,
    divLabel: String,
    isDisabled: Boolean,
    label: String,
    required: Boolean,
    modelValue: [Boolean, Number],
  },

  data: () => ({
    inputValue: '',
  }),

  methods: {
    update() { this.$emit('update:modelValue', this.inputValue) },
    emitClick() { this.$emit('clicked', this.inputValue) }
  },

  emmits: [ "update:modelValue" ],

  created() {
    if (!this.value) this.value = false
    this.inputValue = this.value
  },

  watch: {
    inputValue() { this.update() },

    modelValue() {
      if (this.modelValue != this.inputValue) {
        this.inputValue = this.modelValue
      }
    }
  },
})
</script>

<style>

.form-check-input {
  cursor: pointer;
  width: 1.1em;
  height: 1.1em;
  background-color: white;
  border-radius: 4px;
  vertical-align: middle;
  border: 1px solid #ddd;
}

.form-check-input:checked[type=checkbox] {
  background-color: #602a84;
  background-image: none;
  border: none;
}
</style>