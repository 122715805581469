<template>
  <div>
    <table class="table table-hover table-bordered table-sm text-center">
      <thead class="text-center table-light">
        <tr v-if="headers" class="text-sm">
          <th
            v-for="header in headers"
            :key="header"
            :class="{ 'col-md-1': header.field === 'action' }"
          >
            {{ header.title }}
          </th>
        </tr>
      </thead>
      <tbody v-if="items">
        <tr v-for="(item, index) in items" :key="index">
          <td
            v-for="header in headers"
            :key="header"
            :class="[header.align, 'align-middle']"
            :style="header.width ? `width: ${header.width};` : ''"
            class="text-sm"
          >
            <slot :name="header.field" :item="item">
              {{ item[header.field] }}
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
    <h5 class="text-secondary text-center mt-3" v-if="items.length < 1">
      Nenhum item a ser exibido.
    </h5>
    <div class="row pagination-container mt-3 s-text-primary">
      <div class="col-3 d-flex justify-content-start text-xs">
        <span>
          Total de registros: <b>{{ items.length }}</b> de <b>{{ totalRows }}</b>
        </span>
      </div>
      <div class="col-6 d-flex justify-content-center text-sm s-text-primary">
        <div
          :class="{ disabled: actualPage === 1 }"
          class="pagination-hover me-3"
          @click="emitPreviousPage"
        >
          <i class="bi bi-arrow-left-circle-fill me-1 p-1"></i>
          <span>Anterior</span>
        </div>
        <div
          :class="{ disabled: items.length < 10 || items.length == 0 }"
          class="pagination-hover ms-3"
          @click="emitNextPage"
        >
          <span>Próximo</span>
          <i class="bi bi-arrow-right-circle-fill ms-1"></i>
        </div>
      </div>
      <div class="col-3 d-flex justify-content-end text-xs">
        <span>
          Total de páginas: <b>{{ totalPages }}</b>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headers: { type: Array, required: true },
    items: Array,
    path: String,
    itemsPerPage: { type: String, default: "20" },
    totalPages: Number,
    totalRows: Number,
    actualPage: Number,
  },

  methods: {
    emitPreviousPage() {
      this.$emit("previousPage")
    },

    emitNextPage() {
      this.$emit("nextPage")
    },
  },
};
</script>

<style>
tr:hover {
  background-color: var(--primary-lighten-2);
}

.pagination-hover {
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  cursor: default !important;
}
</style>
