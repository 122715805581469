import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/recoveryPassword/:token',
    name: 'recoveryPassword',
    component: () => import('@/components/login/RecoveryPassword.vue'),
  },
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  /* ########## REGISTROS ########## */
  {
    path: '/vagas',
    name: 'record',
    component: () => import('@/views/registro/vagas/Vagas.vue'),
  },
  {
    path: '/vagas/:id/candidatos',
    name: 'candidate',
    component: () => import('@/views/registro/candidatos/Candidatos.vue'),
  },
  {
    path: '/candidatos/:id',
    name: 'curriculum',
    component: () => import('@/views/registro/candidatos/Curriculum.vue'),
  },

  /* ########## Perguntas ########## */
  {
    path: '/perguntas',
    name: 'question',
    component: () => import('@/views/question/Pergunta.vue'),
  },
  {
    path: '/perguntas/cadastro/novo',
    name: 'questionNew',
    component: () => import('@/views/question/PerguntaNovo.vue'),
  },
  {
    path: '/perguntas/:id/editar',
    name: 'questionUpdate',
    component: () => import('@/views/question/PerguntaNovo.vue'),
  },

  /* ########## GRUPO ACL ########## */
  {
    path: '/vagas/:idJob/perguntas',
    name: 'jobQuestions',
    component: () => import('@/views/question/jobs/PerguntaVaga.vue'),
  },
  {
    path: '/vagas/:idJob/perguntas/novo',
    name: 'jobQuestionsNew',
    component: () => import('@/views/question/jobs/PerguntaVagaNovo.vue'),
  },
  {
    path: '/vagas/:idJob/perguntas/:id/editar',
    name: 'jobQuestionsUpdate',
    component: () => import('@/views/question/jobs/PerguntaVagaNovo.vue'),
  },

  /* ########## USUARIO ########## */
  {
    path: '/administracao/usuario',
    name: 'user',
    component: () => import('@/views/administracao/usuario/Usuario.vue'),
  },
  {
    path: '/administracao/usuario/cadastro/novo',
    name: 'userNew',
    component: () => import('@/views/administracao/usuario/UsuarioNovo.vue'),
  },
  {
    path: '/administracao/usuario/:id/editar',
    name: 'userUpdate',
    component: () => import('@/views/administracao/usuario/UsuarioNovo.vue'),
  },
  /* ########## GRUPO USUÁRIO ########## */
  {
    path: '/administracao/usuario/:idUser/grupoUsuario',
    name: 'userGroupChildren',
    component: () => import('@/views/administracao/usuario/grupoUsuario/GrupoUsuarioPai.vue'),
  },
  {
    path: '/administracao/usuario/:idUser/grupoUsuario/cadastro/novo',
    name: 'userGroupNew',
    component: () => import('@/views/administracao/usuario/grupoUsuario/GrupoUsuarioNovo.vue'),
  },
  {
    path: '/administracao/usuario/:idUser/grupoUsuario/:id/editar',
    name: 'userGroupUpdate',
    component: () => import('@/views/administracao/usuario/grupoUsuario/GrupoUsuarioNovo.vue'),
  },
  /* ########## GRUPO ########## */
  {
    path: '/administracao/grupo',
    name: 'group',
    component: () => import('@/views/administracao/grupo/Grupo.vue'),
  },
  {
    path: '/administracao/grupo/cadastro/novo',
    name: 'groupNew',
    component: () => import('@/views/administracao/grupo/GrupoNovo.vue'),
  },
  {
    path: '/administracao/grupo/:id/editar',
    name: 'groupUpdate',
    component: () => import('@/views/administracao/grupo/GrupoNovo.vue'),
  },
  /* ########## GRUPO ACL ########## */
  {
    path: '/administracao/grupo/:id_group/acl',
    name: 'aclChildren',
    component: () => import('@/views/administracao/grupo/acl/AclPai.vue'),
  },
  {
    path: '/administracao/grupo/:id_group/acl/novo',
    name: 'aclNew',
    component: () => import('@/views/administracao/grupo/acl/AclNovo.vue'),
  },
  {
    path: '/administracao/grupo/:id_group/acl/:id/editar',
    name: 'aclUpdate',
    component: () => import('@/views/administracao/grupo/acl/AclNovo.vue'),
  },
  /* ########## PROGRAMA ########## */
  {
    path: '/administracao/programa',
    name: 'screen',
    component: () => import('@/views/administracao/programa/Programa.vue'),
  },
  {
    path: '/administracao/programa/cadastro/novo',
    name: 'screenNew',
    component: () => import('@/views/administracao/programa/ProgramaNovo.vue'),
  },
  {
    path: '/administracao/programa/:id/editar',
    name: 'screenUpdate',
    component: () => import('@/views/administracao/programa/ProgramaNovo.vue'),
  },
  /* DASHBOARD */
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
  },
  /* ########## PERFIL ########## */
  {
    path: '/meu-perfil',
    name: 'myProfile',
    component: () => import('@/views/Perfil.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
