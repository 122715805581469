<template>
  <div class="col-md-7 divContent s-text-light s-bg-dark">
    <div class="row ms-3 mt-4">
      <div class="col-md-12">Bem-vindo ao</div>
      <div class="col-md-12">
        <img class="img-product" src="@/assets/img/nill_logo_white.svg"/>
      </div>
      <div class="col-md-12">
        Sistema de gerenciamento de registros
      </div>
    </div>
    <div class=" d-flex flex-column align-items-center">
      <img class="img-content" src="@/assets/img/login-illustration.svg"/>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.img-content {
  background-repeat: no-repeat;
  height: 70%;
  width: 50%;
  position: absolute;
  bottom: 0;
}

.img-product {
  margin: 5px 0;
  width: 180px;
  height: 62px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>