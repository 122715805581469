<template>
  <div>
    <s-menu />
    <s-toast-success ref="toastSuccess" :message="toastMessage" />
  </div>
</template>

<script>
import { mapState } from "vuex"
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js"

export default {
  computed: {
    ...mapState(["logged", "showToast", "toastMessage", "user"]),
  },

  methods: {
    logout() {
      new bootstrap.Toast(this.$refs.toastSuccess.$refs.toastPattern).show()
      this.$store.dispatch("setShowToast", false);
    },
    isRoutePublic(url) {
      const publicRoutes = ['/recoveryPassword', '/recoveryPassword/:token']

      return publicRoutes.some((route) => url.includes(route))
    },
  },

  async beforeMount() {
    if (!this.isRoutePublic(document.URL)) {
      try {
        const sessionIsValid = await this.$checkSession()
        console.log('Sessão é válida?', sessionIsValid)

        if (!sessionIsValid) {
          this.$router.push({ name: 'login' })
        }
      } catch (error) {
        console.error('Erro ao verificar a sessão:', error)
      }
    }
  },

  async mounted() {
    if (!this.isRoutePublic(document.URL)) {
      try {
        const sessionIsValid = await this.$checkSession()
        console.log('Sessão é válida?', sessionIsValid)

        if (!sessionIsValid) {
          this.$router.push({ name: 'login' })
        }
      } catch (error) {
        console.error('Erro ao verificar a sessão:', error)
      }
    }
  },

  watch: {
    showToast() {
      if (this.showToast) {
        this.logout()
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/app.scss";
@import "@/style/theme.css";
// @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');

* {
  // font-family: "Poppins", sans-serif !important;
  font-family: "Lexend", sans-serif !important;
}

.icon_action {
  cursor: pointer;
}
</style>
