<template>
  <h4>Home View</h4>
</template>

<script>
export default {

}
</script>

<style>

</style>