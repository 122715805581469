<template>
  <div
    class="modal fade"
    ref="modalPattern"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header s-bg-primary s-text-light">
          <span class="fs-5">Atenção !</span>
          <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
        </div>
        <div class="modal-body text-dark">
          <span>Deseja realmente excluir o registro ?</span>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <s-button
            type="button"
            label="Cancelar"
            color="primary"
            colorFont="light"
            class="text-sm"
            data-bs-dismiss="modal"
            @click="emitCancel"
          />
          <s-button
            type="button"
            label="Excluir"
            color="light"
            colorFont="secondary"
            class="text-sm"
            data-bs-dismiss="modal"
            @click="emitConfirm"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modalPattern",

  methods: {
    emitConfirm() {
      this.$emit("confirm");
    },

    emitCancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style></style>