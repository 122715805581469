<template>
  <div :class="`mb-3 ${divClass}`">
    <s-label :label="label" :required="required" />
    <div class="input-group">
      <input
        :ref="ref"
        v-model="inputValue"
        type="text"
        :disabled="isDisabled"
        @input="updateValue"
        :placeholder="placeholder"
        :minlength="minlength"
        :maxlength="maxlength"
        class="form-control form-control-sm text-sm"
        :class="{ 'is-invalid': hasError }"
        @blur="updateValue"
        @keydown.enter.prevent="emitEnter"
      />
      <span class="input-group-text text-sm iconButton" v-if="!inputValue">
        <i class="bi bi-search"></i>
      </span>
      <span class="input-group-text text-sm iconButton" @click="clear" v-else>
        <i class="bi bi-x-lg"></i>
      </span>
    </div>
    <s-invalid-feedback v-if="hasError">
      {{ error }}
    </s-invalid-feedback>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { validateRequired } from "@/rule/validate";

export default defineComponent({
  props: {
    ref: String,
    divClass: String,
    label: String,
    isDisabled: Boolean,
    placeholder: String,
    required: Boolean,
    minlength: String,
    maxlength: String,
    modelValue: [String, Number],
  },

  inheritAttrs: false,

  data: () => ({
    inputValue: "",
    error: null,
    hasError: false,
  }),

  methods: {
    update() {
      this.$emit("update:modelValue", this.inputValue);
    },

    updateValue() {
      validateRequired(
        this.inputValue,
        this.required,
        this,
        `${this.label} é obrigatório.`,
        this.minlength,
        `${this.label} deve ter no mínimo ${this.minlength} caracteres.`
      )

      this.update()
    },

    emitEnter() { this.$emit("enter") },

    clear() {
      this.inputValue = null
      this.updateValue()
    },
  },

  emmits: ["update:modelValue"],

  created() {
    this.inputValue = this.modelValue;
  },

  watch: {
    inputValue() {
      this.update();
    },

    modelValue() {
      if (this.modelValue != this.inputValue) {
        this.inputValue = this.modelValue;
      }
    },
  },
});
</script>

<style></style>