<template>
    <div class="d-flex align-items-center card-status text-white text-sm mb-2" :style="{ backgroundColor: object.background }" v-if="object">
      <i class="me-1 ps-2" :class="object.icon" ></i>
      <label class="text-sm">{{ object.label }}</label>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      status: String,
    },
  
    data: () => ({
      object: null
    }),
  
    methods: {
      filterStatus(status) {
        switch (status) {
          case "active":
            this.object = {
              label: "Ativo",
              background: "bg-light",
              icon: "bi bi-list"
            }
            break;
          case "errorNotSentLink":
            this.object = {
              label: "Ativo",
              background: "bg-danger",
              icon: "bi bi-list"
            }
            break;
          case "errorNotSentResult":
            this.object = {
              label: "Resultado",
              background: "bg-light",
              icon: "bi bi-x-lg"
            }
            break;
          case "pending":
            this.object = {
              label: "Pendente",
              background: "bg-light",
              icon: "bi bi-clock-fill"
            }
            break;
          case "finished":
            this.object = {
              label: "Concluído",
              background: "bg-primary",
              icon: "bi bi-check-circle-fill"
            }
            break;
          case "expired":
          this.object = {
              label: "Expirado",
              background: "#EC9F00",
              icon: "bi bi-exclamation-circle-fill"
            }
            break;
          case "disable":
          this.object = {
              label: "Desativado",
              background: "#84828F",
              icon: "bi bi-link-45deg"
            }
            break;
        }
      }
    },
  
    mounted() {
      this.filterStatus(this.status)
    },
  
    watch: {
      status() {
        this.filterStatus(this.status)
      }
    }
  }
  </script>
  
  <style>
  .card-status {
    width: 100px;
    height: 22px;
    border-radius: 4px;
  }
  </style>